'use client'

import React from 'react'

import { motion } from 'framer-motion';
import { Link } from "react-scroll";
import {default as NextLink} from "next/link";

import { BaseIcon } from '@/components/UI/Icons/BaseIcon/BaseIcon';
import BaseBages from '@/components/UI/Icons/BaseBages/BaseBages';

import vkImage from "../../../../public/img/vk-2.svg";
import gitHub from "../../../../public/img/github.svg";
import telegramImage from "../../../../public/img/telegram.svg";
import cityIcon from "../../../../public/img/city.svg";
import {usePathname} from "next/navigation";
import Image from "next/image";

interface IBurgerMenu {
    closeMenu: (value: boolean) => void
}

const pagesNavigationLink = {
    '/' : [
        {
            name: 'Портфолио',
            href: '/portfolio',
            isNextLink: true,
        },
        {
            name: 'Биография',
            href: 'about-block',
            isNextLink: false,
        },
        {
            name: 'F.A.Q',
            href: 'faq',
            isNextLink: false,
        },
        {
            name: 'Контакты',
            href: 'contact',
            isNextLink: false,
        }
    ],
    '/portfolio': [
        {
            name: 'На главную',
            href: '/',
            isNextLink: true,
        },
        {
            name: 'Контакты',
            href: 'contact',
            isNextLink: false,
        }
    ]
}

export const BurgerMenu:React.FC<IBurgerMenu> = ({ closeMenu }) => {
    const pathname = usePathname();

    const renderNav = React.useCallback( () => {
        // @ts-ignore
        const currentNav : any = pagesNavigationLink[pathname];

        return (
            <>
                {currentNav.map((nav : any) => (
                    <li className="burger-items" key={nav.href}>
                        {nav.isNextLink ?
                            <NextLink onClick={() => closeMenu(false)} href={nav.href}>
                                {nav.name}
                            </NextLink>
                            :
                            <Link activeClass='active' smooth={true} to={nav.href} offset={-73}
                                  onClick={() => closeMenu(false)}
                                  duration={1000}>
                                {nav.name}
                            </Link>
                        }
                    </li>
                ))
                }
            </>
        )
    }, [pathname])

    return (
        <motion.div className="menu-burger__block"
                    initial={{
                        x: -100,
                        opacity: 0,
                    }}
                    animate={{
                        x: 0,
                        opacity: 1,
                        transition: {
                            duration: 0.1,
                            ease: 'easeIn'
                        }
                    }}
                    exit={{
                        x: -100,
                        opacity: 0,
                        transition: {
                            duration: 0.5,
                ease: 'linear'
            }
        }}
    >
        <Image width={35} height={23} className="burger-close" onClick={() => closeMenu(false)} src="https://cdn.nikolaygusev.ru/landing/img/close.svg" alt="close" />
        <div className="nav">
            <div className="menu-items__block">
                <BaseBages
                  href={'https://t.me/gusev_niiikolay'}
                  target={'blank'}
                  text={'gusev_niiikolay'}
                  src={telegramImage}
                  alt={'telegram'}
                  tagElement={'a'}
                  className="menu-items__block__phone"
                />
                <BaseBages
                  text={'г.Москва'}
                  src={cityIcon}
                  alt={'city'}
                  tagElement={'motion.p'}
                  className="menu-items__block__city"
                />
            </div>
        </div>
        <ul>
            { renderNav() }
        </ul>
        <div className="menu-burger__block-footer">
            {/* <a href="mailto:info@vlad.com" className="menu-burger__block-footer__email">info@vlad.com</a> */}
            <div className="menu-items__social">
                <a href="https://vk.com/niikolay.gusev" target='blank'>
                    <BaseIcon src={vkImage} alt={'vk'} />
                </a>
                <a href="https://github.com/TankistPro" target='blank' className='github-icon'>
                    <BaseIcon src={gitHub} alt={'gitHub'} />
                </a>
            </div>
        </div>
    </motion.div>
  )
}
