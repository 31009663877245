'use client'

import React from 'react'
import Image from 'next/image';
import './baseBages.scss';

import { motion } from 'framer-motion';

type tagElementType = 'a' | 'div' | 'motion.a' | 'motion.div' | 'motion.p' ;

interface IBaseBagesProps {
    href?: string,
    target?: string,
    text: string,
    className?: string,
    src: string,
    alt: string,
    tagElement: tagElementType,
    children?: string | JSX.Element | JSX.Element[],

    initial?: object,
    animate?: object,
    transition?: object

    style?: React.CSSProperties
}

const BaseBages: React.FC<IBaseBagesProps> = (props) => {
    const renderTag = (props: IBaseBagesProps) => {
      const image =
        <>
          <Image src={props.src} alt={props.alt} width={10} height={10} />
          { props.text }
        </>

        switch(props.tagElement) {
            case 'a': return <a
              href={props.href}
              target={props.target}
              className={`base-bages ${ props.className ? props.className : '' }`}
              style={props.style}
              >{image}</a>
            case 'div': return <div
              className={`base-bages ${ props.className ? props.className : '' }`}
              style={props.style}
              >{image}</div>
            case 'motion.a': return <motion.a
              initial={props.initial}
              animate={props.animate}
              transition={props.transition}
              href={props.href}
              target={props.target}
              style={props.style}
              className={`base-bages ${ props.className ? props.className : '' }`}>{image}</motion.a>
            case 'motion.div': return <motion.div
                initial={props.initial}
                animate={props.animate}
                style={props.style}
                transition={props.transition}
                className={`base-bages ${ props.className ? props.className : '' }`}
              >{image}</motion.div>
              case 'motion.p': return <motion.p
                initial={props.initial}
                style={props.style}
                animate={props.animate}
                transition={props.transition}
                className={`base-bages ${ props.className ? props.className : '' }`}
              >{image}</motion.p>
        }
    }
  return (
    renderTag(props)
  )
}

export default BaseBages;
