import React from 'react'

import Image from 'next/image';

interface IBaseIconProps {
    src: string,
    alt: string,
    width?: number,
    height?: number
}

export const BaseIcon: React.FC<IBaseIconProps> = (props) => {
  return (
    <Image 
        {...props}
        width={props.width ? props.width : 28}
        height={props.height ? props.height : 28}
    />
  )
}
