'use client';

import React from 'react';

import './header.scss';
import {AnimatePresence, motion} from "framer-motion";
import {BurgerMenu} from "./BurgerMenu/BurgerMenu";
import BaseBages from "../UI/Icons/BaseBages/BaseBages";
import telegramImage from "../../../public/img/telegram.svg";
import cityIcon from "../../../public/img/city.svg";
import {BaseIcon} from "../UI/Icons/BaseIcon/BaseIcon";
import vkImage from "../../../public/img/vk-2.svg";
import gitHub from "../../../public/img/github.svg";

const Header = () => {
    const [isOpenBurger, setIsOpenBurger] = React.useState<boolean>(false);
    const $menuHeader = React.useRef<HTMLElement>(null);

    React.useEffect(() => {
        const menuHeaderHeight = $menuHeader.current?.offsetHeight || 0;

        toggleHeaderFixed(menuHeaderHeight);

        window.addEventListener("scroll", () => {
            toggleHeaderFixed(menuHeaderHeight);
        })
    }, [])

    const toggleHeaderFixed = (menuHeaderHeight: number) => {
        if((window.scrollY > 150) && !$menuHeader.current?.classList.contains("header-fixed")) {
            $menuHeader.current?.classList.add("header-fixed");
        }

        if (window.scrollY < 150 && window.scrollY > menuHeaderHeight) {
            $menuHeader.current?.classList.add("opacity-0");
        }

        if((window.scrollY <= menuHeaderHeight ) && $menuHeader.current?.classList.contains("header-fixed")) {
            $menuHeader.current?.classList.remove("header-fixed");
            $menuHeader.current?.classList.remove("opacity-0");
            $menuHeader.current!.style.opacity = "1";
        }
    }

    return (
        <nav className="menu" ref={$menuHeader}>
            <div className="menu-burger">
                <motion.img
                    className="burger-icon"
                    src="https://cdn.nikolaygusev.ru/landing/img/menu-burger.svg"
                    alt="menu"
                    onClick={() => setIsOpenBurger(!isOpenBurger)}
                />
                <AnimatePresence>
                    {
                        isOpenBurger && <BurgerMenu closeMenu={setIsOpenBurger}/>
                    }
                </AnimatePresence>
            </div>
            <div className="menu-items">
                <div className="menu-items__block main ">
                    <BaseBages
                        href={'https://t.me/gusev_niiikolay'}
                        target={'blank'}
                        text={'gusev_niiikolay'}
                        src={telegramImage}
                        alt={'telegram'}
                        tagElement={'motion.a'}
                        className="menu-items__block__phone"
                        initial={{
                            y: -500
                        }}
                        animate={{
                            y: 0,
                        }}
                        transition={{
                            duration: 0.5,
                            type: 'spring',
                            bounce: 0.1,
                            mass: 0.6
                        }}
                    />
                    <BaseBages
                        text={'г.Москва'}
                        src={cityIcon}
                        alt={'city'}
                        tagElement={'motion.p'}
                        className="menu-items__block__city"
                        initial={{
                            y: -500
                        }}
                        animate={{
                            y: 0
                        }}
                        transition={{
                            duration: 0.5,
                            delay: 0.1,
                            type: 'spring',
                            bounce: 0.1,
                            mass: 0.6
                        }}
                    />
                </div>
                <div className="menu-items__social nav">
                    <a href="https://vk.com/niikolay.gusev" target='blank'>
                        <BaseIcon src={vkImage} alt={'vk'}/>
                    </a>
                    <a href="https://github.com/TankistPro" target='blank' className='github-icon'>
                        <BaseIcon src={gitHub} alt={'git'}/>
                    </a>
                </div>
            </div>
        </nav>
    );
};

export default Header;
