'use client';
import React from 'react'

import './footer.scss'

import { BaseIcon } from '@/components/UI/Icons/BaseIcon/BaseIcon';
import BaseBages from '@/components/UI/Icons/BaseBages/BaseBages';

import vkImage from "../../../public/img/vk-2.svg";
import gitHub from "../../../public/img/github.svg";
import telegramImage from "../../../public/img/telegram.svg";
import cityIcon from "../../../public/img/city.svg";

const Footer = () => {
  return (
    <footer id="footer">
        <div className="footer-block">
            <BaseBages
              href={'https://t.me/gusev_niiikolay'}
              target={'blank'}
              text={'gusev_niiikolay'}
              src={telegramImage}
              alt={'telegram'}
              tagElement={'a'}
              className="footer-block__phone"
            />
            <BaseBages
              text={'г.Москва'}
              src={cityIcon}
              alt={'city'}
              tagElement={'motion.p'}
              className="footer-block__city"
            />
        </div>
        <div className="footer-social">
            <a href="https://vk.com/niikolay.gusev" target='blank'>
                <BaseIcon src={vkImage} alt={'vk'} />
            </a>
            <a href="https://github.com/TankistPro" target='blank' className='github-icon'>
                <BaseIcon src={gitHub} alt={'git'} />
            </a>
        </div>
        <span className='footer-copyright'>
          © Nukolay Gusev, { `${ new Date().getFullYear() } - ${ new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getFullYear() }`   }
        </span>
    </footer>
  )
}

export default Footer;
